export default {
  API_ROOT: "api.millelacs.gtpdev.com",
  TITLE: "Hard Rock Jackpot Casino - Dev",
  FACEBOOK_PIXEL_BASE: undefined,
  XTREMEPUSH_ID: "IySz1inhhyBmmy3mKyp0FBtTQHCiDWxH",
  APPLICATION_TARGET: "millelacs",
  APPLICATION_NAME: "MILLELACS Dev",
  GOOGLE_CLIENT_ID: "423828920344-nf37kvrl7g4kokvkmf7iskerauq7c1i6.apps.googleusercontent.com",
  GOOGLE_AD_URL: "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js",
  ENVIRONMENT: "dev",
  PRELOAD_ASSETS: "true",
  MOBILE_WEB_ONE_LINK: "https://grandondemandslots.onelink.me/swsD",
  HELP_LINK: "grandondemandhelp.zendesk.com",
  SITE_KEY: "",
  ACCOUNT_EMAIL: "false",
  ACCOUNT_CHANGE_PASSWORD: "false",
  PLAY_AS_GUEST: "true",
  APPLE_STORE_URL: "https://apps.apple.com/us/app/grand-on-demand/id6478566083",
  ANDROID_STORE_URL: "https://play.google.com/store/apps/details?id=com.greentubepro.millelacs",
  DSDK_APP_TOKEN: "30354a9d-fd65-4dd9-a9c0-6d7b50f2fa07",
  DSDK_HASH_KEY: "76D7B907752498D793CE7",
  DSDK_SV_URL: "https://a.bluebat.dive.games/millelacs",
  DSDK_API_URL: "https://api-sandbox.bluebat.dive.games",
  DSDK_DOMAIN: "millelacs.gtpdev.com",
  EXPERIAN_ENVIRONMENT_KEY: undefined,
  SSO_DEV: undefined,
  NODE_ENV: "production",
  CDN_ROOT: "millelacs.gtpdev.com",
  APP_ID: undefined,
  VERSION_MAJOR: "2",
  VERSION_MINOR: "11",
  VERSION_PATCH: "0",
  VERSION_BUILD: "1",
  PUBLIC_URL: undefined,
  ZENDESK_URL: "https://grandondemandhelp.zendesk.com/hc/en-us/requests/new",
  LINKING_CARD_POPUP_ON_HIT_REWARD_CENTER: "false",
  SHOW_PROPERTY_SELECTOR: "true",
  DYNAMIC_HIGHLIMIT_POPUP: "true",
  DYNAMIC_PROPERTY: "true",
  KEYCHAIN_SERVICE_KEY: undefined,
  SHARED_PREFERENCES_KEY: undefined,
  DIVE_ERROR_HANDLING: "yes" ? "yes" : 'no',
  ENABLE_API_TRACKING: "true",
  GOOGLE_TAG_MANAGER_CONTAINER_ID: "",
  APPSFLYER_PATH_PREFIX: undefined,
  LANGUAGE: "en",
  PLAY_AS_GUEST_TYPE: "BUTTON",
  ONE_TRUST_DATA_DOMAIN_SCRIPT: "018dfaf5-8459-7fff-8600-a781be450924",
  FACEBOOK_LOGIN: "false",
  COMING_SOON: "false"
};