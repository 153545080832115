import { FTUE_STEPS_KEYS } from 'shared/constants';
import styled from 'styled-components';
import generateRender from 'shared/utils/generateRender';

const viewportWidth = window.innerWidth;
const vwInPixels = viewportWidth / 100;
const GAME_TILE_WIDTH = 16 * vwInPixels;

const scrollBarArrowBottomPosition = generateRender({
  default: '11px',
  admiral: '44px',
  admes: '44px',
});

const getScrollBarConfig = (ftueStep, gameIndex, hasPromotions, totalGamesCount, showBanner, lobbyPromotionsLength) => {
  return {
    isDraggableX: true,
    showVertical: false,
    showHorizontal: totalGamesCount > 8,
    showHorizontalArrows: false,
    showByDefault: true,
    scrollToPosition: ftueStep === FTUE_STEPS_KEYS.HIGHLIGHT_A_GAME || showBanner ? 0 : gameIndex * GAME_TILE_WIDTH,
    customStyle: {
      display: 'flex',
      height: '100%',
      paddingTop: '1vh',
      paddingLeft: hasPromotions ? (lobbyPromotionsLength > 1 ? '30vw' : '31.5vw') : '8vw',
    },
    customWidth: '97vw',
    customArrowStyle: {
      bottom: scrollBarArrowBottomPosition,
    },
    customClassNames: `${hasPromotions ? 'custom-scrollbar-gamepage' : 'custom-scrollbar-gamepage custom-scrollbar-gamepage-no-promo-banner'}`,
  };
};

const MainContainer = styled.div`
  display: flex;
  padding-bottom: 2rem;

  @media only screen and (max-height: 780px) {
    padding-bottom: 3.5rem;
  }
`;

export { getScrollBarConfig, MainContainer };

